
// 获取一级分类
const getFirstLevelUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/category/level/first`
// 获取二级分类
const getSecondLevelUrl = `${API_CONFIG.butlerBaseURL}electronicPatrol/category/level/`

export {
  getFirstLevelUrl,
  getSecondLevelUrl
}
