// import { generateMapByOpts } from 'common/utils'
import { getFirstLevelUrl, getSecondLevelUrl } from './api'
import { communityParams } from 'common/select2Params'
import Vue from 'vue'
const getFirstOpts = async (postData) => {
  let res = await Vue.prototype.$axios.get(getFirstLevelUrl, { params: postData })
  let firstLevelOps = []
  if (res.status === 100) {
    if (res.data && res.data.length > 0) {
      res.data.forEach(item => {
        firstLevelOps.push({
          text: item.name,
          value: item.id
        })
      })
    }
  }
  return firstLevelOps
}

const getSecondOps = function (firstId, secondLevelOps, cb) {
  if (firstId) {
    Vue.prototype.$axios.get(getSecondLevelUrl + firstId)
      .then(res => {
        if (res.status === 100) {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              secondLevelOps.push({
                text: item.name,
                value: item.id
              })
            })
          }
          if (cb) {
            cb()
          }
        }
      })
  }
}

export {
  getFirstOpts,
  getSecondOps,
  communityParams
}
